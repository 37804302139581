<template>
  <div>
    <list-layout ref="govChannel" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <channel-list-filter is-gov :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template #item_sort="{row}">{{row.sort}}</template>
      <template #item_cover="{row}">
        <list-image :src="row.cover" :height="150" is-image />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovChannels } from "@/modules/gov/api/channel-library";
import ChannelListFilter from "@/modules/wx-channels/components/video-list/ListFilter";
import ListImage from "@/base/components/List/ListImage";

export default {
  components: {ListImage, ChannelListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        account_id: "",
        feed_id: "",
        category_id: -1, //分类id
        category_name: '',
        title: "", // 标题
        page_size: 15, //每页多少条数据
        page: 1,
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "排序", prop: "sort", minWidth: 100, type: 'slot' },
        { label: "视频封面", prop: "cover", minWidth: 100, type: 'slot' },
        {
          label: "视频标题",
          prop: "title",
          minWidth: 110,
        },
        {
          label: "分类",
          prop: "category_name",
          minWidth: 100,
        },
        {
          label: "浏览量",
          prop: "view_count",
          minWidth: 100,
          // sortable: true,
        },
        {label: "视频ID", prop: "feed_id"},
        {label: "视频号ID", prop: "account_id"},
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          // sortable: true,
        },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovChannels(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govChannel.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
